<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>标记管理</el-breadcrumb-item>
        <el-breadcrumb-item>监管管理</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="pt0">
      <el-row class="box search mt20" :gutter="10">
        <el-form :inline="true" :model="form" class="demo-form-inline">
          <el-col :span="4">
            <el-form-item size="medium">
              <el-input clearable placeholder="请输入标记部门" v-model="form.name" class="input-with-select"
                @change="onSubmit(form.name)" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item size="medium">
              <el-button type="primary" @click="search" class="general_bgc general_border cfff" disabled>查询</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item size="medium">
              <el-button type="primary" @click="add" class="general_bgc general_border cfff">新增标记部门</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" fit>
          <el-table-column type="index" width="120" label="序号" align="center"></el-table-column>
          <el-table-column prop="name" label="标记部门名称" align="center" min-width="120"
            :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="操作" fixed="right" min-width="100" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope">
              <el-button type="text" class="orange" size="medium" @click="toEdit(scope.row)">编辑</el-button>
              <el-button type="text" class="orange" size="medium" @click="addSign(scope.row)">标记企业</el-button>
              <el-button type="text" class="cac" size="medium" @click="toSupervisor(scope.row)">监管员</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr mt20">
          <el-pagination v-if="total!=0" background
            @current-change="currentChange" :current-page="currentPage" :page-sizes="[10,20,30,50]"
            :page-size="parseInt(formData.pager_openset)" layout="total, sizes, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
      </div>
      <el-dialog :title="title" :visible.sync="dialogAddgsVisible" style="width: 100%" @close="closeDialogAddgsVisible">
        <el-form :inline="true" :model="form" class="demo-form-inline" style="height: 100px">
          <el-col :span="6" class="mr10">
            <el-form-item size="medium">
              <el-input
                      placeholder="标记部门名称"
                      v-model="form.name"
                      clearable
                      class="input-with-select"
                      @clear="onSubmit(form.name)"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" class="tl mr10" >
            <el-form-item size="medium">
              <el-button type="primary"  size="medium" @click="onSubmit"
              >{{option}}</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-dialog>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { mapGetters } from "vuex";
import { local,session } from "../../../util/util";

export default {
  data () {
    return {
      form:{
        id:'',
        name:'',
      },
      formData:{
        company_name:"",
        pager_offset: "0",
        pager_openset: "10",
      },
      option:'添加',
      dialogAddgsVisible:false,
      statusOptions: [
        { label: "全部", value: "" },
        { label: "启用", value: "1" },
        { label: "禁用", value: "2" }
      ],
      currentPage: 1,
      total: 0,
      tableData: [],
      userData: {},
      activeName: "1"
    };
  }, filters: {
    statusFilter (status) {
      const statusMap = {
        1: "已启用",
        2: "已禁用"
      };
      return statusMap[status];
    },
    antiStateFilter (state) {
      const stateMap = {
        1: "禁用",
        2: "启用"
      };
      return stateMap[state];
    }
  },
  computed: {
    ...mapGetters({
      departmentPage: "department/departmentPage"
    }),
  },
  mounted () {
    this.userData = local.get("user");
    this.init(this.form);
  },
  methods: {
    init(){
      axios.get("/pc/department/signList",this.formData).then(res=>{
        res = res.data;
        if (res){
            this.tableData = res.list;
        }
      })
    },
    closeDialogAddgsVisible(){
      this.dialogAddgsVisible = false;
    },
    add(){
      this.dialogAddgsVisible = true;
      this.form.id = "";
      this.form.name = "";
    },
    handleSelectionChange(val){
      this.companyId = JSON.stringify(val);
    },
    //添加
    onSubmit () {
     axios.post("/pc/department/addSign",this.form).then(res=>{
       if (res){
         this.$message({
           message:"添加成功",
           type:"success"
         })
       }
     })
    },
    search(){
      this.init(this.form);
    },
    Add(){
      session.set('params',{id:'',name:''})
      this.$router.push({path: 'department/add'})
    },
    toEdit(row){
      this.dialogAddgsVisible = true;
      this.form.id = row.id;
      this.form.name = row.name;
      this.option = "修改"

    },
    toSupervisor(row){
      session.set('signDepartment',{id:row.id})
      this.$router.push({ path: "signSupervisor"})
    },
    addSign(row) {
      session.set('signDepartment',{id:row.id})
      this.$router.push({
        path: "companySignList"
      });
    }
  },
  currentChange(page) {
    this.currentPage = page;
    this.form.pager_offset = String((page - 1) * 10);
    this.getData(this.form);
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
